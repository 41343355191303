import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const Hero = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="text-center hero my-5">
      {isAuthenticated ? (
        <p>Now click on the <b>Stock</b> menu to use it!</p>
      ) : (
        <>
          <h2 className="mb-4">Welcome! Please log in.</h2>
        </>
      )}
      <div>
        <h1><a href="https://www.When2buy.ai" target="_blank" rel="noopener noreferrer">When2buy.ai</a></h1>
        <p>When2buy Chat: <a href="https://stock-qa.when2buy.ai" target="_blank" rel="noopener noreferrer">Chat</a></p>
        <p className="mt-4">Latest updates：</p>
        <a href="https://www.when2buy.ai/post/when2buy-version-updates" target="_blank" rel="noopener noreferrer">
        https://www.when2buy.ai/post/when2buy-version-updates</a>
      </div>
    </div>
  );
};

export default Hero;
