import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import API_BASE_URL from './const.js';

const StockTradeLogs = () => {
    const [stockName, setStockName] = useState('NVDA');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [stockData, setStockData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1); // 设置为昨天
        const eightDaysAgo = new Date();
        eightDaysAgo.setDate(today.getDate() - 6);
        
        setEndDate(today.toISOString().split('T')[0]);
        setStartDate(eightDaysAgo.toISOString().split('T')[0]);
    }, []);

    const isDateRangeValid = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const minDate = new Date('2020-01-01');
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (startDate < minDate) {
            setError('开始日期必须在2020-01-01之后。');
            return false;
        } else if (endDate > today) {
            setError('结束日期不能在未来。');
            return false;
        } else if (startDate > endDate) {
            setError('开始日期不能晚于结束日期。');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const isValidStock = async (stock) => {
        const url = `${API_BASE_URL}/api/validate_stock?stock_name=${stock}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.valid;
        } catch (error) {
            console.error('Error validating stock symbol:', error);
            return false;
        }
    };

    const handleAnalyze = async () => {
        if (!startDate || !endDate) {
            setError('请选择开始和结束日期。');
            return;
        }

        if (!isDateRangeValid(startDate, endDate)) {
            return;
        }

        console.log(stockName);
        const isValid = await isValidStock(stockName);
        console.log(isValid);
        if (!isValid) {
            setError('Invalid stock symbol. Please enter a valid stock symbol, e.g., NVDA for Nvidia, AAPL for Apple Inc.');
            return;
        }

        setLoading(true);
        setError('');

        const token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                `${API_BASE_URL}/api/get_ai_trade_logs?stock_name=${stockName}&start_date=${startDate}&end_date=${endDate}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail);
            }
            const data = await response.json();
            setStockData(data);
            setShowTable(true);
            console.log(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const tableStyles = {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        borderRadius: '8px',
        overflow: 'hidden'
    };

    const thStyles = {
        backgroundColor: '#f4f6f8',
        padding: '12px 15px',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '600',
        color: '#2c3e50',
        borderBottom: '2px solid #ddd'
    };

    const tdStyles = {
        padding: '12px 15px',
        borderBottom: '1px solid #ddd'
    };

    const trStyles = {
        ':hover': {
            backgroundColor: '#f5f5f5'
        }
    };

    const renderTable = () => {
        console.log(">>> ", stockData);
        if (stockData.length === 0) {
            return <div>无操作</div>;
        }
        return (
            <table style={tableStyles}>
                <thead>
                    <tr>
                        <th style={thStyles}>Date</th>
                        <th style={thStyles}>Price</th>
                        <th style={thStyles}>Buy or Sell</th>
                        <th style={thStyles}>Num of Shares</th>
                        <th style={thStyles}>Total Budget</th>
                        <th style={thStyles}>Cash Remain</th>
                        <th style={thStyles}>Purchase Value</th>
                        <th style={thStyles}>Commission</th>
                    </tr>
                </thead>
                <tbody>
                    {stockData.map((data, index) => (
                        <tr key={index} style={trStyles}>
                            <td style={tdStyles}>{data.date}</td>
                            <td style={tdStyles}>${Number(data.price).toFixed(2)}</td>
                            <td style={{
                                ...tdStyles,
                                color: data.buy_or_sell?.toLowerCase() === 'buy' ? '#16a34a' : '#dc2626'
                            }}>
                                {data.buy_or_sell ? data.buy_or_sell.toUpperCase() : 'N/A'}
                            </td>
                            <td style={tdStyles}>{data.num_of_shares}</td>
                            <td style={tdStyles}>${Number(data.total_budget).toFixed(2)}</td>
                            <td style={tdStyles}>${Number(data.cash_remain).toFixed(2)}</td>
                            <td style={tdStyles}>${Number(data.purchase_value).toFixed(2)}</td>
                            <td style={tdStyles}>${Number(data.commission).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
            <div className="chart-tab">
                <div className="input-container">
                    <input
                        type="text"
                        value={stockName}
                        onChange={(e) => setStockName(e.target.value)}
                        placeholder="Stock symbol (e.g., NVDA)"
                        className="input"
                    />
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="input"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="input"
                    />
                    <button onClick={handleAnalyze} className="button" disabled={loading}>
                        {loading ? 'Analyzing...' : 'Analyze Stock'}
                    </button>
                </div>
                {error && <div className="error">{error}</div>}
                {loading && <div className="loading">Loading, it usually takes around 15 seconds</div>}

                {showTable && renderTable()}
            </div>
    );
};

export default StockTradeLogs;
